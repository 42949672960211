<template>
  <div>
    <b-card
      v-if="isReportInProgress"
      bg-variant="gray-200"
      class="mb-3 mb-lg-5 border-0"
    >
      <p class="mb-0">
        Report acquistato. L'analisi è in creazione, attendere prego.
      </p>
    </b-card>
    <b-card
      v-else
      bg-variant="warning"
      border-variant="warning"
      class="mb-3 mb-lg-5"
    >
      <p class="mb-0">
        In questa sezione puoi vedere tutte le informazioni del nostro Click Report
      </p>
      <p class="mb-0">
        Se sei interessato puoi
        <b-button
          variant="info"
          size="lg"
          @click="buyClickReport"
        >
          Acquistare Click Report
        </b-button>
      </p>
    </b-card>
  </div>
</template>

<script>

export default {
  name: 'ProfileWarning',
  components: {},
  props: {
    isReportInProgress: Boolean,
    buyClickReport: Function,
  },
  computed: {},
  methods: {
    buyReport() {
      if (this.$props.buyClickReport) {
        this.$props.buyClickReport();
      }
    },
  },
};
</script>

<style scoped>

</style>
